import React from 'react';
import Logo from './assets/logo-16.png'
import './App.css';

function App() {
  return (
      <div className="App">
        <img alt="logo-papi" src={Logo} />

      </div>
  );
}

export default App;
